@import "contact.scss";
@import "about-us.scss";
@import "presentation-card.scss";
@import "destination.scss";
@import "footer.scss";
@import "navbar.scss";

@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

.section-title {
  align-items: center;
  color: #f0a23e;
  font-size: 60px;
  font-weight: bold;
  margin-top: 20px;
  padding: 50px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Anton", sans-serif;
}

.body-container {
  padding: 0 100px;
  background-color: white;
}

@media (max-width: 960px) {
  .body-container {
    padding: 0 10px;
  }
}
