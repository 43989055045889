.card {
  width: 280px;
  box-shadow: none;
  margin-right: 26px;
}

.card-body {
  width: 290px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border: 1px solid lightgrey;
  border-top: none;
}

.border {
  border-radius: 5px;
}

.no-border {
  border-radius: 5px 5px 0 0;
}

.card-img-top {
  height: 330px;
  width: 290px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.destination-name {
  position: absolute;
  top: 280px;
  left: 20px;
  color: white;
  font-size: 20px;
}

.destination-button {
  position: absolute;
  top: 266px;
  left: 250px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.text {
  opacity: 0;
  height: 0;
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
}

.text.in.visible {
  opacity: 1;
  height: auto;
  transform: translateY(0);
}
