.footer-container {
  background-color: #1f2d1b;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem;
}

.footer-text {
  text-align: center;
  color: white;
  margin: 0;
}

.footer-icon {
  color: white;
  font-size: 30px;
  margin-right: 10px;
}

@media only screen and (max-width: 990px) {
  .footer-container {
    padding: 2rem;
  }
}
