.about-us-container {
  max-width: 60vw;
  margin: 0 auto;
}

.about-text,
.section-subtitle {
  line-height: 1.8;
  text-align: justify;
}

.value-card {
  margin: 0 auto;
  max-width: 250px;
  color: white;
  background-color: #1f2d1b;
  padding: 30px;
  text-align: center;
  max-height: 100%;
  border-radius: 50px;
  margin: 0 20px 20px 0;
}

@media only screen and (max-width: 990px) {
  .value-card {
    margin-bottom: 20px;
  }
  .about-us-container {
    max-width: 80vw;
  }
}
