.contact-container {
  margin: 0 auto;
  max-width: 60vw;
  padding-bottom: 40px;
}

.contact-container {
  justify-content: center;
  align-items: center;
}

.contact-us-img {
  width: 500px;
}

.page-title {
  height: 200px;
}

.page-title p {
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #f0a23e;
}

.ddl-program {
  background-color: #f1f2f5;
  border-color: #e7e7ed;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem;
  box-shadow: none;
  color: #272833;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: 2.5rem;
  line-height: 1.5;
  min-width: 0;
  padding-bottom: 0.4375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.form-check {
  margin-bottom: 20px;
}

.form-label {
  min-width: 170px;
}

.contact-btn {
  margin: 10px 10px 10px 0;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(
    45deg,
    #ff512f 0%,
    #f09819 51%,
    #ff512f 100%
  );
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: white;
    text-decoration: none;
  }

  &:active {
    transform: scale(0.95);
  }
}

.bg-leaf {
  width: 500px;
  position: absolute;
  opacity: 0.1;
  right: 0px;
}

@media only screen and (max-width: 990px) {
  .bg-leaf {
    width: 300px;
    top: 415vh;
  }
}
