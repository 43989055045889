.header-carousel {
  display: flex;
  align-items: center;
  padding: 60px;
  background-color: #f0a23e;

  button,
  [type="button"] {
    width: 10px;
    height: 16px;
    margin: 10px;
    border-radius: 10px;
    border-color: transparent;
  }

  .active {
    transform: scale(1.5);
  }
}

.header-image {
  width: 40vw;
  height: 100%;
  box-shadow: 5px 0 25px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 4px;
}

@media only screen and (max-width: 990px) {
  .header-carousel {
    padding: 0;
    background-color: white;
  }
  .header-image {
    display: none;
  }
}
