.navbar {
  background-color: #553022;
  font-weight: bold;
  height: 90px;
}

.nav-item {
  color: white !important;
  font-size: 28px;
  margin-left: 30px;
  cursor: pointer;
  text-transform: uppercase;
}

.nav-item.active {
  border-bottom: 3px solid white;
}

.navbar-collapse {
  margin-top: 20px;
}

.navbar-toggler {
  border-color: white !important;
  color: white !important;
}

.nav-item {
  height: 40px;
}

.logo {
  display: none;
  width: 65px;
}

@media only screen and (max-width: 990px) {
  .logo {
    display: block;
    background-color: white;
    border-radius: 6px;
  }

  .nav-item {
    font-size: 20px;
  }
}
